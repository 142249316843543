import React, { useState, useEffect } from "react";
import axiosInstance from "../../api/AxiosInatance";

const UpdateService = ({ service, onClose, onUpdate }) => {
  const [serviceName, setServiceName] = useState("");
  const [description, setDescription] = useState("");

  useEffect(() => {
    if (service) {
      setServiceName(service.servicename);
      setDescription(service.description);
    }
  }, [service]);

  const handleUpdate = async () => {
    try {
      const response = await axiosInstance.put(
        `/api/update-updateServiceNameAndDescription/${service._id}`,
        {
          servicename: serviceName,
          description: description,
        }
      );
      onUpdate(response.data); // Notify parent with the updated service
      alert("Service updated successfully!");
      onClose(); // Close modal
    } catch (error) {
      alert("Error updating service. Please try again.");
    }
  };

  return (
    <div style={styles.modalOverlay}>
      <div style={styles.modalContent}>
        <h2>Update Service</h2>
        <div style={styles.formGroup}>
          <label>Service Name:</label>
          <input
            type="text"
            value={serviceName}
            onChange={(e) => setServiceName(e.target.value)}
            required
            style={styles.input}
          />
        </div>
        <div style={styles.formGroup}>
          <label>Description:</label>
          <textarea
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            required
            style={styles.textarea}
          />
        </div>
        <div style={styles.buttonGroup}>
          <button style={styles.btnPrimary} onClick={handleUpdate}>
            Update
          </button>
          <button style={styles.btnSecondary} onClick={onClose}>
            Close
          </button>
        </div>
      </div>
    </div>
  );
};

const styles = {
  modalOverlay: {
    position: "fixed",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    background: "rgba(0, 0, 0, 0.5)",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    zIndex: 1000,
  },
  modalContent: {
    background: "white",
    borderRadius: "8px",
    padding: "20px",
    width: "400px",
    boxShadow: "0 4px 10px rgba(0, 0, 0, 0.1)",
  },
  formGroup: {
    marginBottom: "15px",
  },
  input: {
    width: "100%",
    padding: "10px",
    border: "1px solid #ccc",
    borderRadius: "4px",
  },
  textarea: {
    width: "100%",
    padding: "10px",
    border: "1px solid #ccc",
    borderRadius: "4px",
    resize: "vertical",
  },
  buttonGroup: {
    display: "flex",
    justifyContent: "space-between",
  },
  btnPrimary: {
    padding: "10px 15px",
    border: "none",
    borderRadius: "4px",
    cursor: "pointer",
    backgroundColor: "#007bff",
    color: "white",
  },
  btnSecondary: {
    padding: "10px 15px",
    border: "none",
    borderRadius: "4px",
    cursor: "pointer",
    backgroundColor: "#6c757d",
    color: "white",
  },
};

export default UpdateService;
