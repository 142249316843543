import React, { useEffect, useState } from "react";
import axiosInstance from "../../api/AxiosInatance"; // Adjust the path as needed

const ServiceSelector = ({ onServiceSelect }) => {
  const [services, setServices] = useState([]);
  const [selectedService, setSelectedService] = useState("");

  useEffect(() => {
    const fetchServices = async () => {
      try {
        const response = await axiosInstance.get("/api/get-all-service");
        setServices(response.data);
      } catch (error) {
        console.error("Error fetching services:", error);
      }
    };

    fetchServices();
  }, []);

  const handleSelect = (event) => {
    const serviceId = event.target.value;
    const selected = services.find((service) => service._id === serviceId);
    setSelectedService(selected);
    onServiceSelect(selected);
  };

  return (
    <div className="mb-3">
      <select
        className="form-select"
        value={selectedService?._id || ""}
        onChange={handleSelect}
      >
        <option value="" disabled>
          Select a Service
        </option>
        {services.map((service) => (
          <option key={service._id} value={service._id}>
            {service.servicename}
          </option>
        ))}
      </select>
    </div>
  );
};

export default ServiceSelector;
