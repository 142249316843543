import React, { useEffect, useState } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import axiosInstance from "../../../api/AxiosInatance";
import "./GoogleReview.css";

const GoogleReview = () => {
  const [reviews, setReviews] = useState([]);
  const [editingReview, setEditingReview] = useState(null);
  const [formError, setFormError] = useState("");

  const [firstReviewScriptSrc, setFirstReviewScriptSrc] = useState("");
  const [firstReviewClassName, setFirstReviewClassName] = useState("");

  const fetchReviews = async () => {
    try {
      const response = await axiosInstance.get("/api/get-google-review");
      setReviews(response.data);

      
      if (response.data.length > 0) {
        setFirstReviewScriptSrc(response.data[0].scriptSrc);
        setFirstReviewClassName(response.data[0].className);
      }
    } catch (error) {
      const errorMessage = error.response?.data?.message || "An error occurred";
      setFormError(`Error fetching reviews: ${errorMessage}`);
    }
  };

  const handleCreateOrUpdate = async (values, resetForm) => {
    try {
      if (editingReview) {
        await axiosInstance.put(
          `/api/update-google-review/${editingReview._id}`,
          values
        );
      } else {
        await axiosInstance.post("/api/add-google-review", values);
      }
      resetForm();
      setEditingReview(null);
      fetchReviews();
      setFormError("");
    } catch (error) {
      const errorMessage = error.response?.data?.message || "An error occurred";
      setFormError(` ${errorMessage}`);
    }
  };

  useEffect(() => {
    const script = document.createElement("script");
    script.src = firstReviewScriptSrc;
    script.async = true;

    if (firstReviewScriptSrc) {
      document.body.appendChild(script);
    }

    return () => {
      if (script.parentNode) {
        document.body.removeChild(script);
      }
    };
  }, [firstReviewScriptSrc]);

  const handleDelete = async (id) => {
    try {
      await axiosInstance.delete(`/api/delete-google-review/${id}`);
      fetchReviews();
      setFormError("");
    } catch (error) {
      console.error("Error deleting review:", error.response.data.error);
      setFormError(error.response.data.error);
    }
  };

  const handleEdit = (review) => {
    setEditingReview(review);
  };

  useEffect(() => {
    fetchReviews();
  }, []);

  const initialValues = {
    scriptSrc: editingReview ? editingReview.scriptSrc : "",
    className: editingReview ? editingReview.className : "",
  };

  const validationSchema = Yup.object({
    scriptSrc: Yup.string()
      .test(
        "no-leading-space",
        "scriptSrc cannot start with a space",
        (value) => !value || !/^\s/.test(value)
      )
      .required("Script Source is required"),
    className: Yup.string()
      .test(
        "no-leading-space",
        "className cannot start with a space",
        (value) => !value || !/^\s/.test(value)
      )
      .required("Class Name is required"),
  });

  return (
    <div className="container googleReviewContainer">
      <h1 className="header">Google Reviews</h1>

      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        enableReinitialize={true}
        onSubmit={(values, { resetForm }) => {
          handleCreateOrUpdate(values, resetForm);
        }}
      >
        {({ isSubmitting }) => (
          <Form className="form">
            {formError && <div className="error">{formError}</div>}
            <div className="field-container">
              <label htmlFor="scriptSrc" className="label">
                Script Source
              </label>
              <Field
                type="text"
                name="scriptSrc"
                placeholder="Enter script source"
                className="form-control"
              />
              <ErrorMessage
                name="scriptSrc"
                component="div"
                className="error"
              />
            </div>
            <div className="field-container">
              <label htmlFor="className" className="label">
                Class Name
              </label>
              <Field
                type="text"
                name="className"
                placeholder="Enter class name"
                className="form-control"
              />
              <ErrorMessage
                name="className"
                component="div"
                className="error"
              />
            </div>
            <button
              type="submit"
              disabled={isSubmitting}
              className="submit-button"
            >
              {editingReview ? "Update" : "Create"} Review
            </button>
          </Form>
        )}
      </Formik>

      <div className="review-list">
        <h2>Review List</h2>
        {reviews.map((review) => (
          <div key={review._id} className="review-item">
            <h3>Script Url : {review.scriptSrc}</h3>
            <p className="mt-3">Script Class Name : {review.className}</p>
            <div className="button-container">
              <button
                onClick={() => handleEdit(review)}
                className="edit-button"
              >
                Edit
              </button>
              {/* <button
                onClick={() => handleDelete(review._id)}
                className="delete-button"
              >
                Delete
              </button> */}
            </div>
          </div>
        ))}
        <div className={firstReviewClassName} data-elfsight-app-lazy></div>
      </div>
    </div>
  );
};

export default GoogleReview;
