import React, { useState } from "react";
import { Formik, Form, Field, FieldArray, ErrorMessage } from "formik";
import * as Yup from "yup";
import axiosInstance from "../../api/AxiosInatance";
import { AiFillDelete } from "react-icons/ai";
import ServiceSelector from "./ServiceSelector";

const ServiceComponent = () => {
  const [mainImage, setMainImage] = useState(null);
  const [error, setError] = useState("");

  const [selectedService, setSelectedService] = useState(null);

  const handleServiceSelect = (service, setFieldValue) => {
    setSelectedService(service);
    setFieldValue("servicename", service.servicename);
    setFieldValue("description", service.description);
  };

  const initialValues = {
    servicename: "",
    description: "",
    serviceModel: [
      {
        title: "",
        description: "",
        image: null,
        requirements: [
          {
            documents: "",
            documentItems: [],
            procedure: "",
            procedureItems: [],
          },
        ],
      },
    ],
  };

  const validationSchema = Yup.object().shape({
    servicename: Yup.string()
      .required("Service name is required.")
      .test(
        "no-leading-space",
        "Cannot start with a space",
        (value) => !value || !/^\s/.test(value)
      )
      .test(
        "no-special-chars",
        "Can only contain letters",
        (value) => !value || /^[A-Za-z\s]+$/.test(value)
      )
      .max(50, "Service name must most 50 characters"),

    description: Yup.string()

      .test(
        "no-leading-space",
        "Cannot start with a space",
        (value) => !value || !/^\s/.test(value)
      )
      .min(50, "Description must be at least 50 characters")
      .max(500, "Description must most 500 characters"),

    mainImage: Yup.mixed()
      .required("Please upload a valid image file (JPEG, JPG, PNG, AVIF)")
      .test("fileSize", "File size must be less than 2MB", (value) => {
        if (!value) return false;
        const sizeInMB = value.size / (1024 * 1024);
        return sizeInMB <= 2;
      })
      .test(
        "fileType",
        "Only supported file type : JPEG , JPG , PNG , AVIF",
        (value) => {
          if (!value) return false;
          const allowedTypes = [
            "image/jpeg",
            "image/png",
            "image/jpg",
            "image/avif",
          ];
          return allowedTypes.includes(value.type);
        }
      ),

    serviceModel: Yup.array().of(
      Yup.object().shape({
        title: Yup.string()
          .required("Model title is required.")
          .test(
            "no-special-chars",
            "Can only contain letters",
            (value) => !value || /^[A-Za-z\s]+$/.test(value)
          )
          .test(
            "no-leading-space",
            "Cannot start with a space",
            (value) => !value || !/^\s/.test(value)
          )
          .max(100, "Service name must most 100 characters"),

        description: Yup.string()
          .required("Model description is required.")
          .test(
            "no-leading-space",
            "Cannot start with a space",
            (value) => !value || !/^\s/.test(value)
          )
          .min(50, "Model description must be at least 50 characters")
          .max(255, "Model description must most 255 characters"),

        // image: Yup.mixed()
        //   .required(
        //     "Please upload a valid image file (JPEG, JPG, PNG, GIF, WEBP)"
        //   )
        //   .test("fileSize", "File size must be less than 2MB", (value) => {
        //     if (!value) return false;
        //     const sizeInMB = value.size / (1024 * 1024);
        //     return sizeInMB <= 2;
        //   }),

        requirements: Yup.array().of(
          Yup.object().shape({
            documents: Yup.string()
              .test(
                "no-special-chars",
                "Can only contain letters",
                (value) => !value || /^[A-Za-z\s]+$/.test(value)
              )
              .test(
                "no-leading-space",
                "Cannot start with a space",
                (value) => !value || !/^\s/.test(value)
              )
              .max(100, "Document must be at most 100 characters"),

            procedure: Yup.string()
              .test(
                "no-special-chars",
                "Can only contain letters",
                (value) => !value || /^[A-Za-z\s]+$/.test(value)
              )
              .test(
                "no-leading-space",
                "Cannot start with a space",
                (value) => !value || !/^\s/.test(value)
              )
              .max(100, "Procedure must be at most 100 characters"),

            documentItems: Yup.array()
              .of(
                Yup.string()
                  .max(255, "Document Item must be at most 255 characters")
                  .test(
                    "no-leading-space",
                    "Cannot start with a space",
                    (value) => !value || !/^\s/.test(value)
                  )
              )
              .min(0, "At least one document item is recommended."), // Change min to 0

            procedureItems: Yup.array()
              .of(
                Yup.string()
                  .max(255, "Procedure Item must be at most 255 characters")
                  .test(
                    "no-leading-space",
                    "Cannot start with a space",
                    (value) => !value || !/^\s/.test(value)
                  )
              )
              .min(0, "At least one procedure item is recommended."), // Change min to 0
          })
        ),
      })
    ),
  });

  const handleMainImageChange = (e, setFieldValue) => {
    const file = e.target.files[0];
    setMainImage(file);
    setFieldValue("mainImage", file);
  };

  const handleSubmit = async (values, { resetForm }) => {
    const formData = new FormData();
    formData.append("image", mainImage);
    formData.append("servicename", values.servicename);
    formData.append("description", values.description);

    // Append serviceModel directly as an array
    formData.append("serviceModel", JSON.stringify(values.serviceModel));

    values.serviceModel.forEach((model) => {
      if (model.image) {
        formData.append(`modelImages[${model.title}]`, model.image);
      }
    });

    try {
      const response = await axiosInstance.post("/api/add-service", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });

      alert("service Added Successfully");
      resetForm();
      setMainImage(null);
      setError("");
    } catch (error) {
      if (error.response && error.response.status === 401) {
        alert("Session expired. Please log in again.");
        localStorage.removeItem("auth");
        window.location.href = "/login";
      } else {
        alert(error.response?.data?.message || error.message);
        setError("Failed to create service. Please try again.");
      }
    }
  };

  return (
    <div className="container mt-5">
      <h2 className="text-center text-warning">Add Service</h2>
      <hr />

      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({ setFieldValue, isSubmitting, values }) => (
          <Form className="my-5">
            {error && <div className="alert alert-danger">{error}</div>}
            <section
              className="rounded p-3"
              style={{ border: "2px solid #f2f2f2" }}
            >
              <div className="d-flex justify-content-center">
                <ServiceSelector
                  onServiceSelect={(service) =>
                    handleServiceSelect(service, setFieldValue)
                  }
                />
              </div>
              <p className="text-center">Or</p>
              <div className="my-1">
                <label className="form-label">Service Name:</label>
                <Field
                  type="text"
                  name="servicename"
                  className="form-control"
                />
                <ErrorMessage
                  name="servicename"
                  component="div"
                  className="text-danger"
                />
              </div>
              <div className="mb-3">
                <label className="form-label">Description:</label>
                <Field
                  as="textarea"
                  name="description"
                  className="form-control"
                />
                <ErrorMessage
                  name="description"
                  component="div"
                  className="text-danger"
                />
              </div>
            </section>

            <div className="mb-3">
              <label className="form-label">Main Image:</label>
              <input
                type="file"
                className="form-control"
                onChange={(e) => handleMainImageChange(e, setFieldValue)}
                accept=".jpg,.jpeg,.png,.avif"
                required
              />
              <ErrorMessage
                name="mainImage"
                component="div"
                className="text-danger"
              />
            </div>

            <h5 className="my-2 text-primary">Add Service Models:</h5>
            <hr />
            <FieldArray name="serviceModel">
              {({ push, remove }) => (
                <>
                  {values.serviceModel.map((model, index) => (
                    <div key={index} className="border p-3 mb-3 rounded">
                      <div className="mb-3 ">
                        <Field
                          type="text"
                          name={`serviceModel[${index}].title`}
                          placeholder="Model Title"
                          className="form-control mb-2"
                        />
                        <ErrorMessage
                          name={`serviceModel[${index}].title`}
                          component="div"
                          className="text-danger mb-1"
                        />
                        <Field
                          as="textarea"
                          name={`serviceModel[${index}].description`}
                          placeholder="Model Description"
                          className="form-control mb-2"
                        />
                        <ErrorMessage
                          name={`serviceModel[${index}].description`}
                          component="div"
                          className="text-danger"
                        />
                        {/* <input
                          type="file"
                          className="form-control mb-2"
                          onChange={(e) => {
                            const file = e.target.files[0];
                            setFieldValue(`serviceModel[${index}].image`, file);
                          }}
                          accept="image/*"
                          required
                        /> */}
                        <ErrorMessage
                          name={`serviceModel[${index}].image`}
                          component="div"
                          className="text-danger"
                        />
                      </div>
                      <FieldArray name={`serviceModel[${index}].requirements`}>
                        {({
                          push: pushRequirement,
                          remove: removeRequirement,
                        }) => (
                          <>
                            <h6 className="my-2 text-primary">
                              Requirements:
                              <span className="ms-2" style={{ color: "#888" }}>
                                (optionals)
                              </span>
                            </h6>
                            {model.requirements.map((req, reqIndex) => (
                              <div key={reqIndex} className="mb-3">
                                <Field
                                  type="text"
                                  name={`serviceModel[${index}].requirements[${reqIndex}].documents`}
                                  placeholder="Document"
                                  className="form-control mb-2"
                                />
                                <ErrorMessage
                                  name={`serviceModel[${index}].requirements[${reqIndex}].documents`}
                                  component="div"
                                  className="text-danger"
                                />

                                <Field
                                  type="text"
                                  name={`serviceModel[${index}].requirements[${reqIndex}].procedure`}
                                  placeholder="Procedure"
                                  className="form-control mb-2"
                                />
                                <ErrorMessage
                                  name={`serviceModel[${index}].requirements[${reqIndex}].procedure`}
                                  component="div"
                                  className="text-danger"
                                />
                                <FieldArray
                                  name={`serviceModel[${index}].requirements[${reqIndex}].documentItems`}
                                >
                                  {({
                                    push: pushDocumentItem,
                                    remove: removeDocumentItem,
                                  }) => (
                                    <>
                                      {req.documentItems.map(
                                        (item, itemIndex) => (
                                          <div className="my-2">
                                            <div
                                              key={itemIndex}
                                              className="mb-1 d-flex align-items-center"
                                            >
                                              <Field
                                                type="text"
                                                name={`serviceModel[${index}].requirements[${reqIndex}].documentItems[${itemIndex}]`}
                                                placeholder="Document Item"
                                                className="form-control me-2"
                                              />

                                              <button
                                                type="button"
                                                className="btn btn-danger"
                                                onClick={() =>
                                                  removeDocumentItem(itemIndex)
                                                }
                                              >
                                                <AiFillDelete />
                                              </button>
                                            </div>
                                            <ErrorMessage
                                              name={`serviceModel[${index}].requirements[${reqIndex}].documentItems[${itemIndex}]`}
                                              component="div"
                                              className="text-danger"
                                            />
                                          </div>
                                        )
                                      )}
                                      <button
                                        type="button"
                                        className="btn btn-secondary mb-2 me-4"
                                        onClick={() => pushDocumentItem("")}
                                      >
                                        Add Document Item
                                      </button>
                                    </>
                                  )}
                                </FieldArray>

                                <FieldArray
                                  name={`serviceModel[${index}].requirements[${reqIndex}].procedureItems`}
                                >
                                  {({
                                    push: pushProcedureItem,
                                    remove: removeProcedureItem,
                                  }) => (
                                    <>
                                      {req.procedureItems.map(
                                        (procItem, procIndex) => (
                                          <div className="my-2">
                                            <div
                                              key={procIndex}
                                              className="mb-1 d-flex align-items-center"
                                            >
                                              <Field
                                                type="text"
                                                name={`serviceModel[${index}].requirements[${reqIndex}].procedureItems[${procIndex}]`}
                                                placeholder="Procedure Item"
                                                className="form-control me-2"
                                              />

                                              <button
                                                type="button"
                                                className="btn btn-danger"
                                                onClick={() =>
                                                  removeProcedureItem(procIndex)
                                                }
                                              >
                                                <AiFillDelete />
                                              </button>
                                            </div>
                                            <ErrorMessage
                                              name={`serviceModel[${index}].requirements[${reqIndex}].procedureItems[${procIndex}]`}
                                              component="div"
                                              className="text-danger"
                                            />
                                          </div>
                                        )
                                      )}
                                      <button
                                        type="button"
                                        className="btn btn-secondary mb-2 me-4"
                                        onClick={() => pushProcedureItem("")}
                                      >
                                        Add Procedure Item
                                      </button>
                                    </>
                                  )}
                                </FieldArray>

                                <button
                                  type="button"
                                  className="btn btn-danger mb-2"
                                  onClick={() => removeRequirement(reqIndex)}
                                >
                                  Remove Requirement
                                </button>
                              </div>
                            ))}
                            <button
                              type="button"
                              className="btn btn-primary"
                              onClick={() =>
                                pushRequirement({
                                  documents: "",
                                  procedure: "",
                                  documentItems: [],
                                  procedureItems: [],
                                })
                              }
                            >
                              Add Requirement
                            </button>
                          </>
                        )}
                      </FieldArray>
                      {/* <button
                        type="button"
                        className="btn btn-danger"
                        onClick={() => remove(index)}
                      >
                        Remove Model
                      </button> */}
                    </div>
                  ))}
                  {/* <button
                    type="button"
                    className="btn btn-secondary"
                    onClick={() =>
                      push({ title: "", description: "", requirements: [] })
                    }
                  >
                    Add Service Model
                  </button> */}
                </>
              )}
            </FieldArray>
            <div className="d-flex justify-content-center">
              <button
                type="submit"
                className="btn btn-warning text-white my-3 w-25 p-2"
                disabled={isSubmitting}
              >
                {isSubmitting ? "Loading.." : "Submit"}
              </button>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default ServiceComponent;
