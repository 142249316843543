import React, { useState, Suspense, lazy } from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";
import Spinner from "./spinner/Spinner";
import GoogleReview from "./components/Dashboard/googleReview/GoogleReview";
import ServiceComponent from "./components/service/ServiceComponent";
import ServiceList from "./components/service/ServiceList";

const Layout = lazy(() => import("./Layout"));
const AddPackage = lazy(() =>
  import("./components/Dashboard/AddPackages/AddPackage")
);
const Login = lazy(() => import("./components/login/Login"));
const Review = lazy(() => import("./components/Dashboard/Review/Review"));
const ViewPackages = lazy(() =>
  import("./components/Dashboard/viewPackage/ViewPackages")
);
const Info = lazy(() => import("./components/info/Info"));
const Overview = lazy(() => import("./components/overview/Overview"));
const Error = lazy(() => import("./components/Errorpage/Error"));
const BookingDetails = lazy(() =>
  import("./components/booking-contact/BookingDetails")
);
const Contact = lazy(() => import("./components/contact/Contact"));
const AddAdmin = lazy(() => import("./components/addAdmin/AddAdmin"));

const App = () => {
  const [isLoggedIn, setIsLoggedIn] = useState(() => {
    return localStorage.getItem("auth") ? true : false;
  });

  const handleLogin = () => {
    setIsLoggedIn(true);
  };

  const handleLogout = () => {
    localStorage.removeItem("auth");
    setIsLoggedIn(false);
  };

  return (
    <Router>
      <Suspense fallback={<Spinner />}>
        <Routes>
          <Route
            path="/login"
            element={
              isLoggedIn ? (
                <Navigate to="/" replace />
              ) : (
                <Login onLogin={handleLogin} />
              )
            }
          />
          <Route
            path="/"
            element={
              isLoggedIn ? (
                <Layout onLogout={handleLogout} />
              ) : (
                <Navigate to="/login" replace />
              )
            }
          >
            <Route index element={<Overview />} />
            <Route path="add-package" element={<AddPackage />} />
            <Route path="info" element={<Info />} />
            {/* <Route path="review" element={<Review />} /> */}
            <Route path="google-review" element={<GoogleReview />} />
            <Route path="view-package" element={<ViewPackages />} />
            <Route path="add-admin" element={<AddAdmin />} />
            <Route path="booking-details" element={<BookingDetails />} />
            <Route path="contact-details" element={<Contact />} />
            <Route path="service" element={<ServiceComponent />} />
            <Route path="service-list" element={<ServiceList />} />
          </Route>
          <Route path="*" element={<Error />} />
        </Routes>
      </Suspense>
    </Router>
  );
};

export default App;
