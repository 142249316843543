// src/components/Spinner.js
import React from "react";
import "./Spinner.css";
import logo from "../asset/logo/logo.png";

const Spinner = () => {
  return (
    <div className="spinner-container">
      <div className="spinner">
        <img src={logo} alt="Loading" className="spinner-image" />
        <div className="spinner-border"></div>
      </div>
    </div>
  );
};

export default Spinner;
