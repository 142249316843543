import React, { useEffect } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import axiosInstance from "../../api/AxiosInatance";
import "./service.css";
import { MdOutlineDeleteOutline } from "react-icons/md";

const UpdateServiceModal = ({ model, onClose, onUpdate, serviceId }) => {
  const formik = useFormik({
    initialValues: {
      title: model.title || "",
      description: model.description || [""],
      requirements: model.requirements || [
        {
          documents: "",
          documentItems: [""],
          procedure: "",
          procedureItems: [""],
        },
      ],
      image: null,
    },
    validationSchema: Yup.object({
      title: Yup.string()
        .required("Model title is required.")
        .test(
          "no-leading-space",
          "Cannot start with a space",
          (value) => !value || !/^\s/.test(value)
        ),
      description: Yup.array()
        .of(
          Yup.string()
            .required("Description is required.")
            .test(
              "no-leading-space",
              "Cannot start with a space",
              (value) => !value || !/^\s/.test(value)
            )
        )
        .min(1, "At least one description is required."),
      requirements: Yup.array().of(
        Yup.object({
          documents: Yup.string().test(
            "no-leading-space",
            "Cannot start with a space",
            (value) => !value || !/^\s/.test(value)
          ),
          documentItems: Yup.array().of(
            Yup.string()
              .test(
                "no-leading-space",
                "Cannot start with a space",
                (value) => !value || !/^\s/.test(value)
              )
              .max(255, "Document Item must be at most 255 characters")
          ),
          procedure: Yup.string().test(
            "no-leading-space",
            "Cannot start with a space",
            (value) => !value || !/^\s/.test(value)
          ),
          procedureItems: Yup.array().of(
            Yup.string()
              .test(
                "no-leading-space",
                "Cannot start with a space",
                (value) => !value || !/^\s/.test(value)
              )
              .max(255, "Procedure Item must be at most 255 characters")
          ),
        })
      ),
      image: Yup.mixed()
        .nullable()
        .test(
          "fileSize",
          "File size must be less than 2MB",
          (value) => !value || value.size / (1024 * 1024) <= 2
        )
        .test(
          "fileType",
          "Only supported file types: JPEG, JPG, PNG, AVIF",
          (value) => {
            if (!value) return true;
            const allowedTypes = [
              "image/jpeg",
              "image/png",
              "image/jpg",
              "image/avif",
            ];
            return allowedTypes.includes(value.type);
          }
        ),
    }),

    onSubmit: async (values, { setSubmitting, setErrors }) => {
      const formDataToSend = new FormData();
      formDataToSend.append("modelId", model._id);
      formDataToSend.append("serviceId", serviceId);
      formDataToSend.append("title", values.title);

      values.description.forEach((desc, index) => {
        formDataToSend.append(`description[${index}]`, desc);
      });

      if (values.image) {
        formDataToSend.append("image", values.image);
      }

      values.requirements.forEach((req, index) => {
        formDataToSend.append(
          `requirements[${index}][documents]`,
          req.documents
        );
        req.documentItems.forEach((item, itemIndex) => {
          formDataToSend.append(
            `requirements[${index}][documentItems][${itemIndex}]`,
            item
          );
        });
        formDataToSend.append(
          `requirements[${index}][procedure]`,
          req.procedure
        );
        req.procedureItems.forEach((item, itemIndex) => {
          formDataToSend.append(
            `requirements[${index}][procedureItems][${itemIndex}]`,
            item
          );
        });
      });

      try {
        const response = await axiosInstance.put(
          `/api/update-service/${serviceId}`,
          formDataToSend,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );
        onUpdate(response.data);
        onClose();
        alert("Service Model update success");
      } catch (error) {
        if (error.response) {
          const errorMessage = error.response.data?.message || error.message;
          setErrors({ submit: errorMessage });
          alert(errorMessage);
        } else {
          alert("An unexpected error occurred.");
        }
      } finally {
        setSubmitting(false);
      }
    },
  });

  useEffect(() => {
    if (model) {
      formik.setValues({
        title: model.title || "",
        description: model.description || [""],
        requirements: model.requirements || [
          {
            documents: "",
            documentItems: [""],
            procedure: "",
            procedureItems: [""],
          },
        ],
        image: null,
      });
    }
  }, [model]);

  const handleAddRequirement = () => {
    formik.setFieldValue("requirements", [
      ...formik.values.requirements,
      {
        documents: "",
        documentItems: [""],
        procedure: "",
        procedureItems: [""],
      },
    ]);
  };

  const handleRequirementChange = (index, field, value) => {
    const updatedRequirements = [...formik.values.requirements];
    updatedRequirements[index][field] = value;
    formik.setFieldValue("requirements", updatedRequirements);
  };

  const handleAddDocumentItem = (reqIndex) => {
    const updatedRequirements = [...formik.values.requirements];
    updatedRequirements[reqIndex].documentItems.push("");
    formik.setFieldValue("requirements", updatedRequirements);
  };

  const handleAddProcedureItem = (reqIndex) => {
    const updatedRequirements = [...formik.values.requirements];
    updatedRequirements[reqIndex].procedureItems.push("");
    formik.setFieldValue("requirements", updatedRequirements);
  };

  const handleDeleteDocumentItem = (reqIndex, itemIndex) => {
    const updatedRequirements = [...formik.values.requirements];
    updatedRequirements[reqIndex].documentItems.splice(itemIndex, 1);
    formik.setFieldValue("requirements", updatedRequirements);
  };

  const handleDeleteProcedureItem = (reqIndex, itemIndex) => {
    const updatedRequirements = [...formik.values.requirements];
    updatedRequirements[reqIndex].procedureItems.splice(itemIndex, 1);
    formik.setFieldValue("requirements", updatedRequirements);
  };

  const handleImageChange = (event) => {
    formik.setFieldValue("image", event.currentTarget.files[0]);
  };

  return (
    <div
      className="modal fade show"
      style={{ display: "block", backgroundColor: "rgba(0,0,0,0.5)" }}
    >
      <div className="modal-dialog modal-dialog-centered">
        <div className="modal-content" style={{ width: "100%" }}>
          <div className="modal-header">
            <h5 className="modal-title">Update Model</h5>
            <button
              type="button"
              className="btn-close"
              onClick={onClose}
            ></button>
          </div>
          <form onSubmit={formik.handleSubmit}>
            <div
              className="modal-body"
              style={{ maxHeight: "60vh", overflowY: "auto" }}
            >
              <div className="mb-3">
                <label className="form-label">Title</label>
                <input
                  type="text"
                  name="title"
                  className={`form-control ${
                    formik.errors.title ? "is-invalid" : ""
                  }`}
                  value={formik.values.title}
                  onChange={formik.handleChange}
                />
                {formik.errors.title && (
                  <div className="invalid-feedback">{formik.errors.title}</div>
                )}
              </div>

              <label className="form-label">Description</label>
              {formik.values.description.map((desc, index) => (
                <div key={index} className="mb-3 d-flex align-items-center">
                  <input
                    type="text"
                    className={`form-control ${
                      formik.errors.description?.[index] ? "is-invalid" : ""
                    }`}
                    value={desc}
                    onChange={(e) => {
                      const updatedDescriptions = [
                        ...formik.values.description,
                      ];
                      updatedDescriptions[index] = e.target.value;
                      formik.setFieldValue("description", updatedDescriptions);
                    }}
                  />
                  <button
                    type="button"
                    className="btn btn-danger btn-sm ms-2"
                    onClick={() => {
                      const updatedDescriptions =
                        formik.values.description.filter((_, i) => i !== index);
                      formik.setFieldValue("description", updatedDescriptions);
                    }}
                  >
                    <MdOutlineDeleteOutline />
                  </button>
                </div>
              ))}
              <button
                type="button"
                className="btn btn-primary btn-sm"
                onClick={() =>
                  formik.setFieldValue("description", [
                    ...formik.values.description,
                    "",
                  ])
                }
              >
                Add Description Item
              </button>

              <h6 className="my-2 fw-bold">Image Upload</h6>
              <div className="mb-3">
                <input
                  type="file"
                  className={`form-control ${
                    formik.errors.image ? "is-invalid" : ""
                  }`}
                  onChange={handleImageChange}
                />
                {formik.values.image && (
                  <div className="mt-2">
                    <strong>Selected File:</strong> {formik.values.image.name}
                  </div>
                )}
                {formik.errors.image && (
                  <div className="invalid-feedback">{formik.errors.image}</div>
                )}
              </div>

              <h6 className="my-2 fw-bold">Requirements</h6>
              {formik.values.requirements.map((req, reqIndex) => (
                <div
                  key={reqIndex}
                  className="requirement-section mb-3 border p-2 rounded"
                >
                  <div className="mb-2">
                    <label className="form-label">Documents</label>
                    <input
                      type="text"
                      className={`form-control ${
                        formik.errors.requirements?.[reqIndex]?.documents
                          ? "is-invalid"
                          : ""
                      }`}
                      value={req.documents}
                      onChange={(e) =>
                        handleRequirementChange(
                          reqIndex,
                          "documents",
                          e.target.value
                        )
                      }
                    />
                    {formik.errors.requirements?.[reqIndex]?.documents && (
                      <div className="invalid-feedback">
                        {formik.errors.requirements[reqIndex].documents}
                      </div>
                    )}
                  </div>

                  <label className="form-label">Document Items</label>
                  {req.documentItems.map((item, itemIndex) => (
                    <div
                      key={itemIndex}
                      className="document-item mb-2 d-flex align-items-center"
                    >
                      <input
                        type="text"
                        className={`form-control me-2 ${
                          formik.errors.requirements?.[reqIndex]
                            ?.documentItems?.[itemIndex]
                            ? "is-invalid"
                            : ""
                        }`}
                        value={item}
                        onChange={(e) => {
                          const updatedItems = [...req.documentItems];
                          updatedItems[itemIndex] = e.target.value;
                          handleRequirementChange(
                            reqIndex,
                            "documentItems",
                            updatedItems
                          );
                        }}
                      />
                      <button
                        className="btn btn-danger btn-sm"
                        onClick={() =>
                          handleDeleteDocumentItem(reqIndex, itemIndex)
                        }
                      >
                        <MdOutlineDeleteOutline />
                      </button>
                    </div>
                  ))}
                  <button
                    className="btn btn-primary btn-sm"
                    onClick={(e) => {
                      e.preventDefault();
                      handleAddDocumentItem(reqIndex);
                    }}
                  >
                    Add Document Item
                  </button>

                  <div className="mb-2 mt-3">
                    <label className="form-label">Procedure</label>
                    <input
                      type="text"
                      className={`form-control ${
                        formik.errors.requirements?.[reqIndex]?.procedure
                          ? "is-invalid"
                          : ""
                      }`}
                      value={req.procedure}
                      onChange={(e) =>
                        handleRequirementChange(
                          reqIndex,
                          "procedure",
                          e.target.value
                        )
                      }
                    />
                    {formik.errors.requirements?.[reqIndex]?.procedure && (
                      <div className="invalid-feedback">
                        {formik.errors.requirements[reqIndex].procedure}
                      </div>
                    )}
                  </div>

                  <label className="form-label">Procedure Items</label>
                  {req.procedureItems.map((item, itemIndex) => (
                    <div
                      key={itemIndex}
                      className="procedure-item mb-2 d-flex align-items-center"
                    >
                      <input
                        type="text"
                        className={`form-control me-2 ${
                          formik.errors.requirements?.[reqIndex]
                            ?.procedureItems?.[itemIndex]
                            ? "is-invalid"
                            : ""
                        }`}
                        value={item}
                        onChange={(e) => {
                          const updatedItems = [...req.procedureItems];
                          updatedItems[itemIndex] = e.target.value;
                          handleRequirementChange(
                            reqIndex,
                            "procedureItems",
                            updatedItems
                          );
                        }}
                      />
                      <button
                        className="btn btn-danger btn-sm"
                        onClick={() =>
                          handleDeleteProcedureItem(reqIndex, itemIndex)
                        }
                      >
                        <MdOutlineDeleteOutline />
                      </button>
                    </div>
                  ))}
                  <button
                    className="btn btn-primary btn-sm"
                    onClick={(e) => {
                      e.preventDefault();
                      handleAddProcedureItem(reqIndex);
                    }}
                  >
                    Add Procedure Item
                  </button>
                </div>
              ))}
              <button
                type="button"
                className="btn btn-primary btn-sm mt-3"
                onClick={handleAddRequirement}
              >
                Add Requirement
              </button>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                onClick={onClose}
              >
                Cancel
              </button>
              <button
                type="submit"
                className="btn btn-primary"
                disabled={formik.isSubmitting}
              >
                {formik.isSubmitting ? "Saving..." : "Save"}
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default UpdateServiceModal;
