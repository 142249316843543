import React, { useEffect, useState } from "react";
import axiosInstance from "../../api/AxiosInatance";
import UpdateServiceModal from "./UpdateServiceModal";
import UpdateService from "./UpdateService";

const ServiceList = () => {
  const [services, setServices] = useState([]);
  const [selectedService, setSelectedService] = useState(null);
  const [selectedServiceName, setSelectedServiceName] = useState("");
  const [selectedModel, setSelectedModel] = useState(null);
  const [isModelModalOpen, setIsModelModalOpen] = useState(false);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [serviceId, setServiceId] = useState(null);
  const [isServiceModalOpen, setIsServiceModalOpen] = useState(false);

  useEffect(() => {
    const fetchServices = async () => {
      setLoading(true);
      try {
        const response = await axiosInstance.get("/api/get-all-service");
        setServices(response.data);
      } catch (error) {
        setError("Error fetching services. Please try again.");
      } finally {
        setLoading(false);
      }
    };

    fetchServices();
  }, []);

  const handleUpdateService = (updatedService) => {
    setServices((prev) =>
      prev.map((service) =>
        service._id === updatedService._id ? updatedService : service
      )
    );
  };

  const handleDeleteService = async (id) => {
    const confirmDelete = window.confirm(
      "Are you sure you want to delete this service?"
    );
    if (!confirmDelete) return;

    try {
      await axiosInstance.delete(`/api/delete-service/${id}`);
      setServices((prev) => prev.filter((service) => service._id !== id));
      setSelectedService(null);
      alert("Service deleted successfully");
    } catch (error) {
      handleError(error);
    }
  };

  const handleDeleteModel = async (modelId) => {
    const confirmDelete = window.confirm(
      "Are you sure you want to delete this model?"
    );
    if (!confirmDelete) return;

    try {
      await axiosInstance.delete(
        `/api/delete-service/${serviceId}/modelId/${modelId}`
      );

      setSelectedService((prev) => {
        const updatedModels = prev.serviceModel.filter(
          (model) => model._id !== modelId
        );
        return {
          ...prev,
          serviceModel: updatedModels.length ? updatedModels : [],
        };
      });

      alert("Model deleted successfully");
    } catch (error) {
      handleError(error);
    }
  };

  const handleModelUpdateClick = (model) => {
    setSelectedModel(model);
    setIsModelModalOpen(true);
  };

  const handleModelModalClose = () => {
    setIsModelModalOpen(false);
    setSelectedModel(null);
  };

  const handleUpdateModel = (updatedModel) => {
    setSelectedService((prev) => ({
      ...prev,
      serviceModel: prev.serviceModel.map((model) =>
        model._id === updatedModel._id ? updatedModel : model
      ),
    }));
    handleModelModalClose();
  };

  const handleServiceChange = (event) => {
    const serviceName = event.target.value;
    setSelectedServiceName(serviceName);
    const service = services.find((s) => s.servicename === serviceName);
    setSelectedService(service);
    setServiceId(service ? service._id : null);
  };

  const handleError = (error) => {
    if (error.response && error.response.status === 401) {
      alert("Session expired. Please log in again.");
      localStorage.removeItem("auth");
      window.location.href = "/login";
    } else {
      alert(error.response?.data?.message || error.message);
      setError("An error occurred. Please try again.");
    }
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div className="alert alert-danger">{error}</div>;
  }

  return (
    <div className="container mt-4">
      <h1 className="mb-3 text-warning">Services</h1>
      <hr />
      <div className="mb-4">
        <label htmlFor="serviceSelect" className="form-label">
          Select a Service
        </label>
        <select
          id="serviceSelect"
          className="form-select p-3"
          onChange={handleServiceChange}
          value={selectedServiceName}
        >
          <option value="">Choose a service...</option>
          {services.map((service) => (
            <option key={service._id} value={service.servicename}>
              {service.servicename}
            </option>
          ))}
        </select>
      </div>

      {selectedService && (
        <div className="service-details">
          <h3 className="text-info my-3 text-uppercase fw-bold">
            {selectedService.servicename}
          </h3>
          <p style={{ color: "#999" }}>{selectedService.description}</p>
          <h6 className="my-2">Service Models</h6>
          {(selectedService.serviceModel || []).length > 0 ? (
            selectedService.serviceModel.map((model) => (
              <div key={model._id} className="mb-3 border p-3 rounded">
                <img
                  src={model.image}
                  width="300px"
                  height="300px"
                  alt={model.title}
                />
                <h6 className="fw-bold text-info text-uppercase mt-3">
                  {model.title}
                </h6>
                <p className="mt-3" style={{ color: "#999" }}>
                  {(model.description || []).join(", ")}
                </p>
                <hr />
                {model.requirements && model.requirements.length > 0 ? (
                  model.requirements.map((req) => (
                    <div key={req._id}>
                      {req.documents && req.documents.length > 0 && (
                        <>
                          <h6>Documents</h6>
                          <p className="fw-bold text-uppercase mt-3">
                            {req.documents}
                          </p>
                        </>
                      )}
                      {req.documentItems && req.documentItems.length > 0 && (
                        <>
                          <h6>Document Items</h6>
                          <ul>
                            {req.documentItems.map((item, index) => (
                              <li key={index}>{item}</li>
                            ))}
                          </ul>
                          <hr />
                        </>
                      )}
                      {req.procedure && (
                        <>
                          <h6>Procedure</h6>
                          <p className="fw-bold text-uppercase mt-3">
                            {req.procedure}
                          </p>
                        </>
                      )}
                      {req.procedureItems && req.procedureItems.length > 0 && (
                        <>
                          <h6>Procedure Items</h6>
                          <ul>
                            {req.procedureItems.map((item, index) => (
                              <li key={index}>{item}</li>
                            ))}
                          </ul>
                          <hr />
                        </>
                      )}
                    </div>
                  ))
                ) : (
                  <p>No requirements available.</p>
                )}
                <button
                  className="btn btn-warning me-2"
                  onClick={() => handleModelUpdateClick(model)}
                >
                  Update Model
                </button>
                <button
                  className="btn btn-danger"
                  onClick={() => handleDeleteModel(model._id)}
                >
                  Delete Model
                </button>
              </div>
            ))
          ) : (
            <p>No service models available.</p>
          )}
          <div className="d-flex justify-content-end">
            <button
              className="btn btn-warning me-2 mb-2"
              onClick={() => setIsServiceModalOpen(true)} // Open the modal
            >
              Update Service
            </button>
            <button
              className="btn btn-danger me-2 mb-2"
              onClick={() => handleDeleteService(selectedService._id)}
            >
              Delete Service
            </button>
          </div>
        </div>
      )}

      {isServiceModalOpen && selectedService && (
        <UpdateService
          service={selectedService}
          onClose={() => setIsServiceModalOpen(false)}
          onUpdate={handleUpdateService}
        />
      )}

      {isModelModalOpen && selectedModel && (
        <UpdateServiceModal
          model={selectedModel}
          onClose={handleModelModalClose}
          onUpdate={handleUpdateModel}
          serviceId={serviceId}
        />
      )}
    </div>
  );
};

export default ServiceList;
